import { use } from 'react';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  recentTestsPromise: Promise<any>;
}

const RecentTests: FunctionComponent<Props> = ({ recentTestsPromise }: Props) => {
  const tests = use(recentTestsPromise);
  return (
    <>
      <h2 className="text-center">Recent tests</h2>
      {
        tests.map((test: any, index: number) => (
          <p key={test.id} className="position-relative overflow-hidden">
            <span style={{ marginLeft: '1rem' }}>
              <Link to={`/report/${test.id}`}>{test.url}</Link>
            </span>
            <span style={{ position: 'absolute', right: 0, top: 0, backgroundColor: 'rgb(242, 243, 244)' }}>
              <span className="text-success recent-tests-status-count text-center">{test.successCount}</span> |
              <span className="text-warning recent-tests-status-count text-center">{test.warningCount}</span> |
              <span className="text-error recent-tests-status-count text-center">{test.errorCount}</span>
            </span>
          </p>
        ))
      }
    </>
  );
}

export default RecentTests;