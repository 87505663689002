import { get, post } from './client';

export async function createTest(url: string): Promise<any> {
  return post(`${process.env.REACT_APP_API_URL}/tests`, { url }, { headers: { 'X-API-Key': process.env.REACT_APP_API_KEY as string } });
}

export async function getResult(id: string): Promise<any> {
  return get(`${process.env.REACT_APP_API_URL}/tests/${id}`, { headers: { 'X-API-Key': process.env.REACT_APP_API_KEY as string } }).then((response) => response.data.test);
}

export async function getDNS(domain: string): Promise<any> {
  return get(`https://dns.google/resolve?name=${domain}`);
}

export async function getLastTests(): Promise<any> {
  return get(`${process.env.REACT_APP_API_URL}/tests`, { headers: { 'X-API-Key': process.env.REACT_APP_API_KEY as string } }).then((response) => response.data.tests);
}
