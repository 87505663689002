import type { FunctionComponent, ReactNode } from 'react';
import styles from './FeaturesList.module.css';

interface Props {
  children: ReactNode;
}

const FeaturesList: FunctionComponent<Props> = ({ children }) => (
  <ul className={styles.featuresList}>
    {children}
    <li className={styles.warning} style={{ display: 'none' }}></li>
  </ul>
);

export const WarningItem: FunctionComponent<Props> = ({ children }) => (
  <li className={styles.warning}>{children}</li>
);

export default FeaturesList;
