import type { FunctionComponent } from 'react';

interface Props {
  title: string;
  description: string;
  children: React.ReactNode;
}

const SEO: FunctionComponent<Props> = (props) => (
  <>
    {
      props.title.length === 0 ? (
        <title>Pentest Tool LITE</title>
      ) : (
        <title>{ `${props.title} | Pentest Tool LITE`}</title>
      )
    }
    <meta name="description" content={props.description} />
    { props.children }
  </>
);

export default SEO;
