import type { FunctionComponent } from 'react';
import Table from 'react-bootstrap/Table';
import styles from './Result.module.css';

interface ResultProps {
  tests: any[];
  level: number;
  filteredStatuses: string[];
}

const isCurrentLevelVisible = (result: any, statuses: string[]) => {
  if ('results' in result) {
    return result.results.some((r:any) => isCurrentLevelVisible(r, statuses));
  }
  return statuses.length === 0 || statuses.includes(result.status);
}

const Result: FunctionComponent<ResultProps> = ({ tests, level, filteredStatuses }: ResultProps) => (
  <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <td width="2em"></td>
          <td>Name</td>
          <td>Description</td>
        </tr>
      </thead>
      <tbody>
        {
          tests
            .filter((result: any) => isCurrentLevelVisible(result, filteredStatuses))
            .map((result: any, index: number) => (
            <tr key={index}>
              <td align="center"><span className={styles[result.status.toLowerCase()]}></span></td>
              <td>{result.title}</td>
              <td>{result.description}</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  </>
);

export default Result;
