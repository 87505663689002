import type { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface Props {
  test: any;
}

const JSONReport: FunctionComponent<Props> = ({ test }: Props) => {
  return (
    <Row className="mt-4">
      <Col>
        <pre>
          {JSON.stringify(test, null, 2)}
        </pre>
      </Col>
    </Row>
  );
}

export default JSONReport;
