import { useActionState, useState } from 'react';
import type { FunctionComponent } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { getDNS, createTest } from '../../api/services';

const CreateTestForm: FunctionComponent = () => {
  const navigate = useNavigate();
  const [ error, setError ] = useState<string | null>(null);
  const [ formErrors, setFormErrors ] = useState<null | { url: string }>(null);

  const onSubmit = async (prevState: unknown, state: FormData): Promise<{ url: string }> => {
    const url = state.get('url') as string;
    const urlWithScheme = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

    setError(null);
    setFormErrors(null);

    if (urlWithScheme.indexOf('.') === -1) {
      setFormErrors({ url: 'URL address is not valid!' });
      return { url };
    }

    const dns = await getDNS((new URL(urlWithScheme).hostname));

    if (dns.Status !== 0) {
      setFormErrors({ url: 'No DNS records for this domain!' });
      return { url };
    }

    await createTest(urlWithScheme).then((response) => {
      setTimeout(() => navigate(`/report/${response.data.test.id}`), 100);
    }).catch(error => {
      setError(error.message);
    });

    return { url };
  }

  const [state, formAction, isPending] = useActionState(onSubmit, { url: '' });

  return (
    <Form action={formAction}>
      {
        error !== null ? (
          <Alert variant="danger">
            {error}
          </Alert>
        ) : null
      }
      <Form.Group className="mb-3" controlId="url">
        <Form.Control
          name="url"
          size="lg"
          type="text"
          placeholder="Enter URL"
          defaultValue={state.url}
          isInvalid={formErrors !== null}
          required
        />
        <Form.Control.Feedback type="invalid">
          { formErrors !== null ? formErrors.url : '' }
        </Form.Control.Feedback>
      </Form.Group>

      <div className="text-center">
        <Button variant="outline-primary" type="submit" disabled={isPending}>
          Run tests
        </Button>
      </div>
    </Form>
  );
}

export default CreateTestForm;
