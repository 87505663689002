import type { FunctionComponent } from 'react';
import BarLoader from '../../components/BarLoader';

const RecentTests: FunctionComponent = () => {
  return (
    <>
      <BarLoader As="h2">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
      <BarLoader As="p">&nbsp;</BarLoader>
    </>
  );
}

export default RecentTests;